import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import Footer from './components/Footer/footer'
import Header from './components/Header/Header'
import ModalBanner from './components/Modal/modal-banner.jsx'
import ScrollToTop from './components/Scroll.js'
import { AboutUs } from './pages/about-us/about-us'
import { AddPost } from './pages/add-post/add-new-post'
import Buy from './pages/buy-car/Buy'
import { Contacts } from './pages/contacts/contacts'
import { FullPost } from './pages/full-post/FullPost'
import Home from './pages/home-page/Home'
import { Login } from './pages/Login/index.jsx'
import NotFound from './pages/notfound/NotFound'
import Opening from './pages/opening/Opening.jsx'
import Partners from './pages/partners/Partners.jsx'
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy.jsx'
import TermOfUse from './pages/privacy-policy/TermOfUse.jsx'
import Reviews from './pages/reviews/Reviews.jsx'
import { HomePage } from './pages/Search/home-page'
import { fetchAuthMe } from './store/slices/auth'


function App() {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const isVisited = localStorage.getItem('isVisited');
    if (!isVisited) {
     setTimeout(() => {
         setIsModalOpen(true);
     }, 10000);
      localStorage.setItem('isVisited', 'true');
    }
  }, []);

    React.useEffect(() => {
        dispatch(fetchAuthMe());

        
    }, [dispatch, ]);

    const closeModal = () => {
        setIsModalOpen(false);
      };
      
    return (
        <div className='App'>
            {isModalOpen && <ModalBanner onClose={closeModal} />}
            <Header />

            
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<AboutUs />} />
                <Route path='/opening' element={<Opening />} />
                <Route path='/reviews' element={<Reviews />} />
                <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
                <Route path='/partners' element={<Partners />} />
                <Route path='/termOfUse' element={<TermOfUse />} />
                <Route path='/contacts' element={<Contacts />} />
                <Route path='/add-post' element={<AddPost />} />
                <Route path='/:category/:title/:id' element={<FullPost />} />
                <Route path='/search/:categoryId' element={<HomePage />} />
                <Route path='/posts/:id/edit' element={<AddPost />} />
                <Route path='/buy' element={<Buy />} />
                <Route path='/login' element={<Login />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;