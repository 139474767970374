import React from 'react'
import Banner from './banner.jpg'

const ModalBanner = ({ onClose }) => {
  
  return (
    <div className="modal-overlay-banner">
      <div className="modal-content-banner">
        <h2 className='titleMain mb-3 fw-bold'>Участвуйте в акции от Ava Drive!</h2>
       
        <img className='img-banner' src={Banner} alt="banner" />
        <div>

        <button className='btn2 dopp mt-3 w-full' onClick={onClose}>Закрыть</button>
        </div>
      </div>
       
    </div>
  );
};

export default ModalBanner